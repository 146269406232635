import { PartnerApi } from '@/api';
import { makeCallTriageStoreKey } from './call-triage-store-persister';

const defaultValues = {
  lookupAnimals: [],
  triageJourneyResult: [],
  triageStartedAt: null,
  adviceCompletedAt: null,
  bookedAppointment: null,
  user: null,
  ssn: null,
  selectedLookupAnimalUID: null,
  phoneNumber: null,
  countryCode: '46',
  selectedInsuranceCompany: null,
  isSearching: false,
  lookupError: null,
  appointmentId: null,
  folksamTriageJourneyId: null,
  additionalInformation: '',
  recommendedService: null,
  session: null,
  sessionPartners: [],
  sessionCountryCodes: [],
  clinicReferrals: [],
  finishedJourney: false,
  clinicRecommendationUrl: '',
  bookingConfirmUrl: '',
  isFallback: false,
};

export default {
  state: JSON.parse(JSON.stringify(defaultValues)),
  mutations: {
    setLookupAnimals: (state, payload) => {
      state.lookupAnimals = payload;
    },
    setUser: (state, payload) => {
      state.user = payload;
    },
    setSelectedInsuranceCompany: (state, payload) => {
      state.selectedInsuranceCompany = payload;
    },
    setSSN: (state, payload) => {
      state.ssn = payload;
    },
    setSelectedLookupAnimalUID: (state, payload) => {
      state.selectedLookupAnimalUID = payload;
    },
    setPhoneNumber: (state, payload) => {
      state.phoneNumber = payload;
    },
    setCountryCode: (state, payload) => {
      state.countryCode = payload;
    },
    setIsSearching: (state, payload) => {
      state.isSearching = payload;
    },
    setLookupError: (state, payload) => {
      state.lookupError = payload;
    },
    setSessionPartners: (state, payload) => {
      state.sessionPartners = payload;
    },
    setSessionCountryCodes: (state, payload) => {
      state.sessionCountryCodes = payload;
    },
    setIsFallback: (state, payload) => {
      state.isFallback = payload;
    },
    // results
    setTriageJourneyResult: (state, payload) => {
      state.triageJourneyResult = payload;
    },
    setTriageRecommendation: (state, payload) => {
      state.triageRecommendation = payload;
    },
    setTriageStartedAt: (state, payload) => {
      state.triageStartedAt = payload;
    },
    setAdviceCompletedAt: (state, payload) => {
      state.adviceCompletedAt = payload;
    },
    setBookedAppointment: (state, payload) => {
      state.bookedAppointment = payload;
    },
    setClinicReferrals: (state, payload) => {
      state.clinicReferrals = [...payload];
    },
    setAdditionalInformation: (state, payload) => {
      state.additionalInformation = payload;
    },
    setFinishedJourney: (state, payload) => {
      state.finishedJourney = payload;
    },
    setAppointmentId: (state, payload) => {
      state.appointmentId = payload;
    },
    setFolksamTriageJourneyId: (state, payload) => {
      state.folksamTriageJourneyId = payload;
    },
    setRecommendedService: (state, payload) => {
      state.recommendedService = payload;
    },
    setClinicRecommendationUrl: (state, payload) => {
      state.clinicRecommendationUrl = payload;
    },
    setBookingConfirmUrl: (state, payload) => {
      state.bookingConfirmUrl = payload;
    },
    resetState: state => {
      Object.assign(state, JSON.parse(JSON.stringify(defaultValues)));
    },
    setSession: (state, uuid) => {
      state.session = uuid;
    },
    loadSession: (state, uuid) => {
      if (!uuid) {
        Object.assign(state, JSON.parse(JSON.stringify(defaultValues)));
        return;
      }

      const oldData = sessionStorage.getItem(makeCallTriageStoreKey(uuid));
      if (!oldData) {
        // set to session expired
        return;
      }
      try {
        const parsedData = JSON.parse(oldData);
        Object.assign(state, parsedData);
      } catch (e) {
        console.log(e);
        // set to session faulty
      }
    },
  },
  getters: {
    getLookupAnimals: state => state.lookupAnimals,
    getUser: state => state.user,
    getSSN: state => state.ssn,
    getPhoneNumberFormatted: state =>
      `${state.countryCode}${state.phoneNumber}`,
    getPhoneNumber: state => state.phoneNumber,
    getSelectedInsuranceCompany: state => state.selectedInsuranceCompany,
    getSelectedLookupAnimalUID: state => state.selectedLookupAnimalUID,
    getTriageJourneyResult: state => state.triageJourneyResult,
    getTriageRecommendation: state => state.triageRecommendation,
    getBookedAppointment: state => state.bookedAppointment,
    getClinicReferrals: state => state.clinicReferrals,
    getAdditionalInformation: state => state.additionalInformation,
    getAppointmentId: state => state.appointmentId,
    getFolksamTriageJourneyId: state => state.folksamTriageJourneyId,
    getRecommendedService: state => state.recommendedService,
    getSessionPartners: state => state.sessionPartners,
    getSessionCountryCodes: state => state.sessionCountryCodes,
    getCountryCode: state => state.countryCode,
    getFinishedJourney: state => state.finishedJourney,
    getTriageStartedAt: state => state.triageStartedAt,
    getAdviceCompletedAt: state => state.adviceCompletedAt,
    getClinicRecommendationUrl: state => state.clinicRecommendationUrl,
    getBookingConfirmUrl: state => state.bookingConfirmUrl,
    getIsFallback: state => state.isFallback,
  },
  actions: {
    clearStore({ commit }) {
      commit('resetState');
    },
    async doFolksamLookup({ commit }, payload) {
      commit('setIsSearching', true);
      commit('setLookupError', null);
      try {
        const response = await PartnerApi.lookupFolksam(payload);
        commit('setLookupAnimals', response.data.animals);
        commit('setIsSearching', false);
        return response;
      } catch (error) {
        commit('setLookupError', error.message || 'Something went wrong');
        commit('setIsSearching', false);
        return error;
      }
    },
  },
};
