import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  createMockCase(payload: {}): AxiosPromise {
    return axios.post(`/api/v2/animal-cases/create-mock-case`, payload);
  },
  getAppointmentCase(appointmentId: number): AxiosPromise {
    return axios.get(`/api/v2/animal-cases/vet/appointment/${appointmentId}`);
  },
  getAppointmentCaseByCaseId(caseId: number): AxiosPromise {
    return axios.get(`/api/v2/animal-cases/vet/${caseId}`);
  },
};
