<template>
  <div class="flex-row space-x-1 pt-1 pb-0.5">
    <base-label v-if="labelText.length" color="dark">
      {{ labelText }}
    </base-label>

    <span class="text-navy">
      {{ displayValue }}
      <slot />
    </span>
    <text-button
      v-if="displayValue && hasCopyButton"
      id="copyButton"
      v-tooltip="'Copy to clipboard'"
      size="sm"
      class="ml-1"
      @click="copyValueToClipboard()"
    >
      <fv-icon icon="copy" size="xs" />
    </text-button>
  </div>
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
      default: '',
    },
    displayValue: {
      type: [String, Number],
      default: '',
    },
    hasCopyButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValueCopied: false,
    };
  },
  methods: {
    copyValueToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.displayValue;
      const copyButton = document.getElementById('copyButton');
      copyButton.insertBefore(el, copyButton.childNodes[0]);
      el.focus();
      el.select();
      document.execCommand('copy');
      copyButton.removeChild(el);

      this.$notify({
        group: 'primary',
        title: '',
        text: 'Copied to clipboard',
      });

      this.isValueCopied = true;
      setTimeout(() => {
        this.isValueCopied = false;
      }, 1500);
    },
  },
};
</script>
