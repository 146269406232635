const svTranslations = {
  select_for_referral: 'Remittera hit',
  filter: 'Filtrera',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  value_missing: 'Värde saknas',
  field_cannot_be_empty: 'Fältet får ej vara tomt',
  fetch_address_info: 'Hämta adressinformation',
  information_found: 'Hittad information',
  address: 'Adress',
  latitude: 'Latitud',
  longitude: 'Longitud',
  is_information_correct: 'Verkar informationen stämma?',
  save_coordinates: 'Spara koordinater',
  save_and_move_cursor_sideways: 'Spara och förflytta markör i sidled',
  could_not_find_any_address_info:
    'Kunde inte hitta några träffar. Se till att adressen är korrekt inmatad.',
  something_went_wrong: 'Något gick fel. Var vänlig försök igen senare.',
  address_missing: 'Kliniken saknar adress.',
  cancel: 'Avbryt',
  type_of_animal: 'Djurslag',
  choose_type_of_animal: 'Välj djurslag',
  clinic_accepts: 'Kliniken tar emot',
  add_animal_type: 'Lägg till djurslag',
  add: 'Lägg till',
  contact_information: 'Kontaktinformation',
  name: 'Namn',
  city: 'Stad',
  municipality: 'Län',
  country: 'Land',
  email: 'E-postadress',
  phone: 'Telefonnummer',
  website: 'Hemsida',
  status: 'Status',
  '2288-pilot': '2288-pilot',
  size_general_info: 'Storlek & generell information',
  clinic_type: 'Kliniktyp',
  number_of_vets: 'Antal veterinärer',
  small_animals: 'Smådjur',
  horse: 'Häst',
  general_info: 'Generell information',
  information_horse_service: 'Information hästtjänst',
  referral: 'Remittering',
  email_referral: 'E-postadress för remisser',
  phone_referral: 'Telefonnummer för remisser',
  referral_information: 'Remissinformation',
  location_info: 'Platsinformation',
  coordinates: 'Koordinater',
  services: 'Tjänster',
  add_specific_service: 'Lägg till specifik tjänst',
  animal_group: 'Djurkategori',
  service_category: 'Tjänstekategori',
  save_services: 'Spara tjänster',
  availability: 'Tillgänglighet',
  emergency_care_options: 'Akutsjukvård (behandlingsmöjligheter)',
  emergency_care: 'Akutsjukvård',
  on_call_options: 'Jourberedskap',
  inpatient_care_small_animals: 'Stationärvård (smådjur)',
  inpatient_care_horses: 'Stationärvård (hästar)',
  yes: 'Ja',
  no: 'Nej',
  opening_hours: 'Öppettider',
  opens: 'Öppnar',
  closes: 'Stänger',
  closed: 'Stängt',
  save_opening_hours: 'Spara öppettider',
  opening_hours_saved: 'Öppettider sparade',
  other_opening_hours: 'Övriga öppettider',
  other_opening_hours_placeholder: 'Ex. lunchstängt',
  opening_hours_error:
    'Något gick fel. Vänligen kontrollera att stängningstid är efter öppningstid.',
  opening_hours_missing_error:
    'Både öppning och stängning måste fyllas i (om ej stängt)',
  selected_time_is_in_wrong_format: 'Angiven tid har fel format',
  copy_contact_info: 'Kopiera kontaktuppgifter',
  copy_clinic_name: 'Kopiera klinikens namn',
  copied_to_clipboard: 'Kopierat!',
  phone_number: 'Telefonnummer',
  public: 'Publikt',
  referral_hidden: 'Remiss (hemligt)',
  inpatient_care: 'Stationärvård',
  general_information: 'Generell information',
  horses: 'Hästar',
  horses_field: 'Hästar (fält)',
  animal_species: 'Djurslag',
  agricultural_animals: 'Lantbruksdjur',
  referral_info: 'Remissinformation',
  search: 'Sök',
  no_search_results: 'Inga sökresultat',
  mon: 'Mån',
  tue: 'Tis',
  wed: 'Ons',
  thu: 'Tors',
  fri: 'Fre',
  sat: 'Lör',
  sun: 'Sön',
  monday: 'Måndag',
  tuesday: 'Tisdag',
  wednesday: 'Onsdag',
  thursday: 'Torsdag',
  friday: 'Fredag',
  saturday: 'Lördag',
  sunday: 'Söndag',
  currently_open: 'Öppna just nu',
  clinics: 'Kliniker',
  active_areas: 'Aktiva områden',
  distances: 'Sträckor',
  save: 'Spara',
  no_answer: 'Inget svar gavs',
  generic_error: 'Nåt gick fel, försök igen senare',
  clinic_map: {
    clinic_search_placeholder: 'Sök på klinik eller ort',
    clinic_search_prompt: 'Fyll i sökfältet',
    clinics: 'Kliniker',
    move_around_prompt:
      'Flytta runt kartan eller zooma ut för att hitta fler kliniker.',
    view_full_list: 'Hela listan',
    recommendations: 'Rekommendationer',
    no_results_for: 'Inga resultat för',
  },
  clinic_map_send_form: {
    clinic: 'Klinik',
    mobile_phone: 'Mobilnummer',
    country_code: 'Landskod',
    price: 'Pris',
    quality: 'Kvalitet',
    insurance: 'Försäkring',
    rating: 'Betyg',
    cta: 'Skicka',
    success: 'Rekommendationerna har skickats!',
  },
  remote_booking: {
    select_timeslot: {
      dialog_header: 'Välj en tid',
      dialog_description:
        'Välj en tid för mötet.\nMötet kommer ske genom vår app.',
      no_slots: 'Inga tillgängliga tider funna',
    },
    case_description: {
      title: 'Beskriv fallet',
      description:
        'Var vänlig beskriv fallet med så stor detaljrikedom som möjligt, så vår veterinär får bästa möjighet att hjälpa till.',
      cta: 'Fortsätt',
    },
    confirm_booking: {
      header_title: 'Bekräfta bokning',
      header_description: 'Försäkra dig om att informationen är korrekt.',
      appointment_time: 'Mötets tid',
      phone_number: 'Telefonnummer',
      pet: 'Husdjur',
      vet: 'Vetrinär',
    },
    sms_confirm: {
      header_title: 'Meddelande skickat!',
      header_description:
        'Vi har skickat ett SMS med en länk till en bokningsbekräftelsesida. Fråga djurägaren om hen har mottagit meddelandet och informera dem om att de måste bekräfta bokningen för att den ska gå igenom.',
      waiting_for_confirmation: 'Väntar på bekräftelse',
      no_sms: 'Inget SMS?',
      resend: 'Skicka igen',
      whats_next: {
        title: 'Nästa steg',
        paragraph_1:
          'Mötet kommer att äga rum i vår app och bokningsinformationen har skickats till djurägaren.',
        paragraph_2:
          'Djurägaren måste ladda ned appen från App Store / Play Store. Efter att ha loggat in kommer de att kunna komma åt sitt konto och sin bokning.',
        paragraph_3: 'Dom finner bokningen på appens hemskärm.',
      },
    },
  },
  call_triage: {
    remote_booking_widget: {
      confirm_booking: {
        cta: 'Skicka sms',
      },
    },
    lookup: {
      lookup: 'Sök',
      ssn: 'Personnummer',
      search: 'Sök',
      phone_number: 'Telefonnummer',
      insurance_company: 'Försäkringsbolag',
      clear_form: 'Rensa formulär',
      select_pet_to_start: 'Välj ett djur för att starta',
      lookup_successful: 'Sökning lyckades',
      cant_find_pet: 'Hittar du inte husdjuret?',
      proceed_to_clinic_map: 'Gå vidare till kliniklistan',
      no_animals: 'Kunde inte hitta några djur',
      no_animals_message:
        'Kunde inte hitta några djur kopplade till denna användare',
    },
    recommendation: {
      recommendation: 'Rekommendationer',
      give_advice: 'Ge råd',
      book_appointment: 'Boka tid',
      go_to_clinic: 'Gå till klinik',
      additional_information: 'Ytterligare information',
      triage_title: 'Triage',
      book_appointment_with_firstvet: 'Boka tid med FirstVet',
      book_appointment_description:
        'Rådgiv och boka ett videomöte med en FirstVet veterinär åt användaren. Välj en lämplig tid i bokningsflödet, varpå användaren får en bekräftelse via sms.',
      no_insurance_company_selected: 'Inget försäkringsbolag valt',
      complete: 'Färdig',
      send_recommendation: 'Skicka rekommendationer',
      data_tracking: {
        message: 'Något gick fel, vi kunde inte spåra data, försök igen',
        retry: 'Försök igen',
      },
      pill: {
        recommended: 'Rekommenderad',
        advice:
          'Baserat på de svar som ges kan det här ärendet med största sannolikhet lösas på telefon',
        booking:
          'Baserat på de svar som ges kan det här fallet med största sannolikhet lösas av en veterinär på ett videosamtal',
        clinic:
          'Baserat på de svar som ges, kommer detta fall med största sannolikhet att behöva gå till en fysisk klinik',
      },
      disabled_tooltip: 'Inget djur är valt',
    },
    triage: {
      title: 'Triage',
      start: 'Start triage',
      next: 'Nästa',
      previous: 'Föregående',
      please_select_option: 'Vänligen välj ett alternativ',
      triage_loading_error: 'Kunde inte hitta eller ladda triage-resa',
      additional_information: 'Ytterligare information',
    },
    booking: {
      pet: 'Djur',
      service: 'Tjänst',
      vet: 'Veterinär',
      date_and_time: 'Datum och tid',
      duration: 'Längd',
      select_service: 'Välj en tjänst',
      services_available_description:
        'Följande tjänster är tillgängliga för {company}',
      free: 'Gratis',
    },
    summary: {
      copy: 'Kopiera till urklipp',
      new_customer: 'Ny kund',
      service: {
        clinic: 'Klinik information rekommenderat',
        advice: 'Råd över telefon rekommenderat',
        booking: 'FirstVet möte rekommenderat',
      },
      no_service: 'Ingen recommendation funnen',
      result: 'Triage resultat',
      additional_information: 'Ytterligare information',
      no_additional_information: 'Ingen extra information',
      follow_up: 'Uppföljning',
      no_follow_up_required: 'Ingen uppföljning krävs',
      phone_advice: 'Råd givet över telefon',
      recommendation_label: 'Rekommendation',
      booking_for_customer_label: 'FirstVet bokning skapat för kund',
      referred_for_follow_up: 'Kund refererad till klinik för uppföljning',
      clinic_name: 'Klinikens namn',
      clinic_address: 'Klinikens address',
      booking: 'Boknings',
      no_vet: 'Ingen veterinär tilldelad',
      vet: 'Veterinär',
      start_meeting: 'Mötets start',
      not_verified: 'Ej verifierat',
      verified: 'Verifierat',
      journey_finished: {
        message: 'Denna resa har redan avslutats. Vänligen påbörja en ny triageresa',
        redirect_button: 'Ny kund',
      },
      started_at: 'Började kl',
      completed_at: 'Avslutad kl',
      clinic_recommendation_link: 'Klinikrekommendationslänk',
      booking_confirm_link: 'Bokningsbekräftelselänk',
    },
  },
  free: 'Gratis',
};

export default svTranslations;
