<!-- This component assumes that the grid layout is defined by the parent -->
<template>
  <Fragment>
    <base-label v-if="labelText.length" color="light">
      {{ labelText }}
    </base-label>

    <div>
      <span class="font-bold text-gray-900" :data-testid="testid">
        {{ displayValue }}
        <slot />
      </span>
      <text-button
        v-if="displayValue && hasCopyButton"
        id="copyButton"
        v-tooltip="'Copy to clipboard'"
        size="sm"
        class="ml-1"
        @click="copyValueToClipboard()"
      >
        <fv-icon icon="copy" size="xs" />
      </text-button>
    </div>
  </Fragment>
</template>

<script>
/* IMPORTANT: remove fragment package in vue 3 since it finally has native support.
  this package does a lot of shit to work and should only be used in vue 2
*/
/* TODO: remove fragment in vue 3 */
import { Fragment } from 'vue-fragment';

export default {
  components: { Fragment },
  props: {
    labelText: {
      type: String,
      default: '',
    },
    displayValue: {
      type: [String, Number],
      default: '',
    },
    hasCopyButton: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isValueCopied: false,
    };
  },
  methods: {
    copyValueToClipboard() {
      navigator.clipboard.writeText(this.displayValue);
      this.$notify({
        group: 'primary',
        title: '',
        text: 'Copied to clipboard',
      });
    },
  },
};
</script>

<style scoped>
.contentGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  align-items: center;
}

.editButton {
  width: fit-content;
}
</style>
