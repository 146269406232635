<template>
  <span class="relative inline-flex shadow-sm">
    <component
      :is="is"
      ref="button"
      :type="type"
      :to="to"
      :class="[
        baseClasses,
        colorClasses,
        sizeClasses,
        disabled || loading
          ? 'pointer-events-none cursor-default opacity-75'
          : '',
      ]"
      :role="to ? 'link' : 'button'"
      :disabled="disabled || loading"
      v-bind="$attrs"
      @click="$emit('click')"
    >
      <slot />
      <spinner-overlay color="light" :loading="loading" size="md" />
    </component>
  </span>
</template>

<script>
import { onMounted } from '@vue/composition-api';

export default {
  inheritAttrs: false,

  props: {
    color: {
      validator(value) {
        return [
          'primary',
          'secondary',
          'cancel',
          'light',
          'dark',
          'transparent',
          'action',
          'danger',
        ].includes(value);
      },
      type: String,
      default: 'light',
    },
    size: {
      validator(value) {
        return ['xs', 'sm', 'md', 'lg'].includes(value);
      },
      type: String,
      default: 'md',
    },
    to: {
      type: [Boolean, String, Object],
      default: false,
    },
    value: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    type: {
      type: String,
      validator(value) {
        return ['button', 'submit', 'reset'].includes(value);
      },
      default: 'submit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { refs }) {
    onMounted(() => {
      if (props.focus) {
        refs.button.focus();
      }
    });

    const sizeClasses = {
      sm: 'text-sm py-1 px-2',
      md: 'text-base py-2 px-4',
      lg: 'text-lg py-3 px-4',
    };

    return {
      baseClasses:
        'w-full inline-flex items-center justify-center rounded shadow-sm leading-5 focus:outline-none focus:ring-1 focus:ring-primary-lighter transition ease-in-out duration-150',
      sizeClasses: sizeClasses[props.size],
    };
  },
  computed: {
    colorClasses() {
      const colorClassesObj = {
        primary:
          'bg-gradient-to-b from-primary via-primary to-primary-darker text-white border border-primary focus:border-blue-600 hover:bg-opacity-95 hover:from-primary hover:to-primary',
        secondary:
          'bg-primary text-white border border-primary-light hover:border-primary-lighter',
        cancel:
          'bg-white text-gray-700 border hover:border-gray-500 hover:text-gray-800',
        danger: 'bg-fv-red text-white border border-red-700 border-opacity-70',
        action:
          'bg-action-dark text-white border border-action-dark hover:text-gray-200 ',
        light:
          'bg-gray-50 text-gray-800 border border-gray-300 hover:bg-gray-100 hover:border-gray-400 focus:border-fv-gray-border-darker',
        dark: 'bg-fv-black text-white border border-gray-800 hover:border-gray-700 hover:bg-gray-900',
        transparent: 'bg-transparent text-gray-800 shadow-none',
      };
      return colorClassesObj[this.color];
    },
    is() {
      if (!this.to) {
        return 'button';
      }
      if (
        typeof this.to === 'string' &&
        (this.to.startsWith('http') ||
          this.to.startsWith('https') ||
          this.to.startsWith('mailto'))
      ) {
        return 'a';
      }
      return 'router-link';
    },
  },
};
</script>
