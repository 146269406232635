import { AnimalApi } from '@/api';
import { format, addMonths } from 'date-fns';

const animalInterface = {
  id: '',
  name: '',
  breed: '',
  birthdate: format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
  gender: null,
  weight: 1,
  identification: '',
  description: '',
  insuranceCompany: null,
  insuranceNumber: '',
};

export default {
  state: {
    animal: { ...animalInterface },
    animals: [], // This array should consist of all the AO's animals //
    sharedAnimals: [], // This array should consist of all the AO's shared animals //
    genderOptions: [
      {
        name: 'Male',
        value: 'm',
      },
      {
        name: 'Female',
        value: 'f',
      },
      {
        name: 'Neutered female',
        value: 'x',
      },
      {
        name: 'Neutered male',
        value: 'y',
      },
      {
        name: 'Not specified',
        value: 'u',
      },
    ],

    appointmentHistory: [],
    questionsHistory: [],
    historyFetched: false,

    animalTypes: [],
    animalsHistoryAppointments: [],

    loading: false,
  },

  getters: {
    getAnimal: state => state.animal,
    getAnimalId: state => state.animal.id,
    getGenderOptions: state => state.genderOptions,
    getLoading: state => state.loading,
    getAppointmentHistory: state => state.appointmentHistory,
    getQuestionsHistory: state => state.questionsHistory,
    getGenderName: state => {
      const genderOption =
        state.animal &&
        state.animal.gender &&
        state.genderOptions.find(
          gender => gender.value === state.animal.gender
        );
      return genderOption ? genderOption.name : 'Unknown';
    },
    getAnimalTypes: state => state.animalTypes,
    getAnimalsNotInConsultation: state =>
      state.animals.filter(animal => state.animal.id !== animal.id),
  },

  mutations: {
    setAnimal: (state, payload) => {
      state.animal = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setAppointmentHistory: (state, payload) => {
      state.appointmentHistory = payload;
    },
    setQuestionsHistory: (state, payload) => {
      state.questionsHistory = payload;
    },
    setAnimalTypes: (state, payload) => {
      state.animalTypes = payload;
    },
    setHistoryFetched: (state, payload) => {
      state.historyFetched = payload;
    },
    setAnimals: (state, payload) => {
      state.animals = payload;
    },
    setSharedAnimals: (state, payload) => {
      state.sharedAnimals = payload;
    },
    setAnimalsHistoryAppointments: (state, payload) => {
      const appointmentsWithBooking = payload.filter(
        appointment => appointment.booking_id
      );
      const animalAppointments = {
        [payload[0].animal_id]: appointmentsWithBooking,
      }
      state.animalsHistoryAppointments = {
        ...state.animalsHistoryAppointments,
        ...animalAppointments,
      };
    },
  },

  actions: {
    updateAnimal({ commit }, { id, payload }) {
      commit('setLoading', true);

      return AnimalApi.updateAnimal(id, payload)
        .then(res => {
          commit('setLoading', false);
          if (res?.data?.animal) {
            commit('setAnimal', res.data.animal);
          }
          return res;
        })
        .catch(e => {
          commit('setLoading', false);
          return Promise.reject(e);
        });
    },
    postAnimalData({ commit }, payload) {
      commit('setLoading', true);

      return AnimalApi.postData(payload)
        .then(res => {
          commit('setLoading', false);
          if (res?.data?.animal) {
            commit('setAnimal', res.data.animal);
          }
          return res;
        })
        .catch(e => {
          commit('setLoading', false);
          return Promise.reject(e);
        });
    },

    fetchAnimal({ commit, rootState }, payload) {
      commit('setLoading', true);
      const { locale } = rootState;
      return AnimalApi.fetchAnimal(payload, locale)
        .then(res => {
          commit('setLoading', false);
          commit('setAnimal', res.data.animal);
          return res;
        })
        .catch(e => {
          commit('setLoading', false);
          Promise.reject(e);
        });
    },

    fetchHistory({ commit, rootState }, payload) {
      commit('setHistoryFetched', false);
      const { locale } = rootState;
      return AnimalApi.fetchHistory(payload, locale)
        .then(res => {
          commit('setAppointmentHistory', res.data.appointments);
          commit('setQuestionsHistory', res.data.questions);
          commit('setHistoryFetched', true);
          if (res.data?.appointments?.length > 0) {
            commit('setAnimalsHistoryAppointments', res.data.appointments);
          }

          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },

    setAnimal({ commit }, payload) {
      commit('setAnimal', payload);
    },

    clearAnimal({ commit }) {
      commit('setAnimal', { ...animalInterface });
    },

    async fetchAnimalTypes({ commit, rootState }) {
      const { locale } = rootState;
      try {
        const res = await AnimalApi.fetchAnimalTypes(locale);
        commit('setAnimalTypes', res.data.animal_types);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
